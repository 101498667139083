*,
::before, 
:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  color: #fff !important;
  font-size: 11pt;
  text-decoration: none;
  background: #6f9da5;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: 100%;

}

.joinInput {
  border-radius: 10;
  padding: 15px 20px;
  font-size: 11pt;
}

.mt-20 {
  margin-top: 20px;
}

.onlineIcon {
  margin-right: 5%;
}





/*Message*/

.messageBox {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #6f9da5;
}

.backgroundLight {
  background: #dad8d9;
}